import React from 'react';
import Logo from '../images/quarpay.png';

const TopBar = () => {
  return (
    <div className='container-fluid d-flex align-items-center bg-primary justify-content-center py-2 mb-2'>
      <img src={Logo} alt="QuarPay-logo" className='m-auto' style={{width: "220px"}}/>
    </div>
  );
};

export default TopBar;